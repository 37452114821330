import React, { useEffect, useState } from "react";
import { Card, Space, Table } from "antd";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import AnexoIPuestosTable from "./establecimientos_declarados/AnexoIPuestosTable";
import AnexoIResponsablesTable from "./establecimientos_declarados/AnexoIResponsablesTable";
import AnexoIContratistasTable from "./establecimientos_declarados/AnexoIContratistasTable";
import AnexoISectoresTable from "./establecimientos_declarados/AnexoISectoresTable";
import DeleteButton from "../../../../shared/components/deleteButton";
import EditButton from "../../../../shared/components/editButton";
import Utils from "../../../../shared/helpers/utils";
import EstablecimientosApi from "../../../establecimientos/api/EstablecimientosApi";
import AuthenticationHelper from "../../../../shared/helpers/authenticationHelper";
import AnexoIExportarExcel from "./AnexoIExportarExcel";
import moment from 'moment';

let empleador;

const AnexoIEstablecimientosDeclaradosTable = props => {
  const [establecimientosEmpresa, setEstablecimientosEmpresa] = useState([]);
  const permisosUsuario = AuthenticationHelper.getTipoUsuario() !== 'Operador';
  let presetaciones = JSON.parse(AuthenticationHelper.getPresentacionUsuario());

  useEffect(() => {
    empleador = presetaciones.filter(x => x.Cuit == props.cuit)[0];
    EstablecimientosApi.GetEmpresasByCuit(props.cuit).then((response) => {
      setEstablecimientosEmpresa(response);
    });
  }, []);

  const getEstablecimiento = id => {
    return props.EstablecimientosDeclarados[props.EstablecimientosDeclarados.findIndex(x => x.IdEstablecimientoEmpresa == id)]
  }

  function cargarEstablecimientoExcel(establecimiento) {
    let FECHA_PRESENTACION_EMPLEADOR = moment(props.Presentacion.FechaPresentacionEmpleador).format('YYYY-MM-DD');
    let ESTABLECIMIENTO = Utils.getIdDesc(establecimiento.IdEstablecimientoEmpresa, props.EstablecimientosEmpresa);
    let RESPONSABLE_HyS = [];
    let SECTORES = [];
    let PUESTOS = [];
    let TRABAJADORES = [];
    let AGENTES_CANCERIGENOS = [];

    establecimiento.Responsables.forEach(responsable => {
      if (responsable.IdTipoProfesionalResponsable == 1) {
        RESPONSABLE_HyS.push({ CUIL: responsable.CuilCuit, MATRICULA: responsable.Matricula, HORAS_MENSUALES: responsable.CantHorasAsignadas });
      }
    });
    establecimiento.Puestos.forEach(puesto => {
      PUESTOS.push({ PUESTO: puesto.Nombre, CIUO: puesto.Ciuo });
    });
    establecimiento.Sectores.forEach(sector => {
      SECTORES.push({ SECTOR: sector.Nombre, CIIU: sector.Ciiu });
    });

    let trabajadoresEstablecimiento = props.Trabajadores.filter(x => x.IdEstablecimientoEmpresa == establecimiento.IdEstablecimientoEmpresa);
    trabajadoresEstablecimiento.forEach(trabajador => {
      let CUIL = trabajador.Cuil;
      let FECHA_DE_INGRESO = moment(trabajador.FechaIngreso).format('YYYY-MM-DD');
      let PUESTO_DECLARADO_EN_LA_EMPRESA = "-";
      let SECTOR;
      let AGENTE_DE_RIESGO = "-";
      let FECHA_INICIO_EXPOSICION = "-";
      let FECHA_FIN_EXPOSICION = "-";
      let EXAMENES_MEDICOS = [];

      if (trabajador.Actividades.length > 0) {
        let actividad = trabajador.Actividades[0];
        
        PUESTO_DECLARADO_EN_LA_EMPRESA = establecimiento.Puestos.filter(x => x.IdPuesto == actividad.IdPuestoDeclarado)[0].Nombre;
        SECTOR = establecimiento.Sectores.filter(x => x.IdSector == actividad.IdSectorDeclarado)[0].Nombre;
        AGENTE_DE_RIESGO = null;
        if (actividad.IdSustanciaDeclarada) {
          let sustancia = props.SustanciasDeclaradas.filter(x => x.IdSustanciaDeclarada == actividad.IdSustanciaDeclarada)[0];
          AGENTE_DE_RIESGO = props.Sustancias.filter(x => x.Codigo == sustancia.IdSustancia)[0].CodigoEsop;
        }
        FECHA_INICIO_EXPOSICION = moment(actividad.FechaInicioExposicion).format('YYYY-MM-DD');
        FECHA_FIN_EXPOSICION = actividad.FechaFinExposicion ? moment(actividad.FechaFinExposicion).format('YYYY-MM-DD') : "-" ;
        actividad.ExamenesMedicos.forEach(examen => {
          EXAMENES_MEDICOS.push(Utils.getIdDesc(examen.IdExamen, props.ExamenesMedicos));
        });
      }
      TRABAJADORES.push({ CUIL, FECHA_DE_INGRESO, PUESTO_DECLARADO_EN_LA_EMPRESA, SECTOR, AGENTE_DE_RIESGO, FECHA_INICIO_EXPOSICION, FECHA_FIN_EXPOSICION, EXAMENES_MEDICOS });
    });

    props.SustanciasDeclaradas.filter(x => x.IdEstablecimientoEmpresa == establecimiento.IdEstablecimientoEmpresa).forEach(sustancia => {
      let SUSTANCIA = props.Sustancias.filter(x => x.Codigo == sustancia.IdSustancia)[0].CodigoEsop;
      let EST_AMBIENTALES_ESPECIFICOS = [];
      let EST_BIOLOGICOS_ESPECIFICOS = [];

      sustancia.EstudiosAmbientalesEspecificos.forEach(estudio => {
        EST_AMBIENTALES_ESPECIFICOS.push(estudio.MetodologiaEmpleada);
      });

      sustancia.EstudiosBiologicosEspecificos.forEach(estudio => {
        EST_BIOLOGICOS_ESPECIFICOS.push(estudio.AnalisisEstudiosComplementacion);
      });

      sustancia.PuestosAfectados.forEach(puesto => {
        let puestoExcel = Utils.getIdDesc(puesto.IdPuesto, getEstablecimiento(sustancia.IdEstablecimientoEmpresa)?.Puestos);
        let INFORMA_RIESGOS = puesto.InformaSobreRiesgos ? "SI" : "NO";
        let CAPACITA_SOBRE_RIESGOS = puesto.CapacitacionSobreRiesgos ? "SI" : "NO";
        let ENTREGA_ELEMENTOS_DE_PROTECCION = puesto.EntregaElementosDeProteccion ? "SI" : "NO";
        let MEDIDAS_PREVENTIVAS = [];
        let ELEMENTOS_PROTECCION_PERSONAL = [];

        puesto.MedidasPreventivasDelPuesto.forEach(medida => {
          MEDIDAS_PREVENTIVAS.push(Utils.getIdDesc(medida.IdReferencialDeMedidasPreventivas, props.medidasPreventivas));
        });
        puesto.ElementosProteccionPersonal.forEach(elemento => {
          ELEMENTOS_PROTECCION_PERSONAL.push(Utils.getIdDesc(elemento.IdReferencialDeElementoDeProteccion, props.elementosProteccion));
        });
        let PUESTO = { SUSTANCIA, PUESTO_AFECTADO: puestoExcel, INFORMA_RIESGOS, CAPACITA_SOBRE_RIESGOS, ENTREGA_ELEMENTOS_DE_PROTECCION, ELEMENTOS_PROTECCION_PERSONAL, MEDIDAS_PREVENTIVAS, EST_AMBIENTALES_ESPECIFICOS, EST_BIOLOGICOS_ESPECIFICOS };
        AGENTES_CANCERIGENOS.push(PUESTO);
      });
    });
    return [{FECHA_PRESENTACION_EMPLEADOR, ESTABLECIMIENTO, TRAB_PROPIOS_ADMIN: establecimiento.CantTrabPropiosAdmin, TRAB_PROPIOS_PROD: establecimiento.CantTrabPropiosProd, RESPONSABLE_HyS, PUESTOS, SECTORES, TRABAJADORES, AGENTES_CANCERIGENOS }];
  }

  return (
    <>
      <Table
        style={{ overflow: 'auto' }}
        size="small"
        onClick={useEffect}
        columns={[
          { title: 'Id', dataIndex: 'IdEstablecimientoDeclarado', key: 'IdEstablecimientoDeclarado' },
          { title: 'Id Establecimiento Empresa', dataIndex: 'IdEstablecimientoEmpresa', key: 'IdEstablecimientoEmpresa' },
          {
            title: 'Establecimiento Empresa', dataIndex: 'IdEstablecimientoEmpresa', key: 'IdEstablecimientoEmpresa',
            render: text => Utils.getIdDesc(text, establecimientosEmpresa)
          },
          { title: 'Descripcion Actividad', dataIndex: 'DescripcionActividad', key: 'DescripcionActividad' },
          { title: 'Cant Trab Eventuales Prod', dataIndex: 'CantTrabEventualesProd', key: 'CantTrabEventualesProd' },
          { title: 'Cant Trab Eventuales Admin', dataIndex: 'CantTrabEventualesAdmin', key: 'CantTrabEventualesAdmin' },
          { title: 'Cant Trab Propios Prod', dataIndex: 'CantTrabPropiosProd', key: 'CantTrabPropiosProd' },
          { title: 'Cant Trab Propios Admin', dataIndex: 'CantTrabPropiosAdmin', key: 'CantTrabPropiosAdmin' },
          { title: 'Mail', dataIndex: 'Mail', key: 'Mail' },
          { title: 'Telefono', dataIndex: 'Telefono', key: 'Telefono' },
          { title: 'Cuil Contacto', dataIndex: 'CuilContacto', key: 'CuilContacto' },
          { title: 'Permitido Fumar', dataIndex: 'PermitidoFumar', key: 'PermitidoFumar', render: text => text == true ? <CheckOutlined /> : <CloseOutlined /> },
          { title: 'Lugares Cerrados Para Fumar', dataIndex: 'LugaresCerradosParaFumar', key: 'LugaresCerradosParaFumar', render: text => text == true ? <CheckOutlined /> : <CloseOutlined /> },
          props.formMode ? {
            title: '', dataIndex: 'Actions', key: 'Actions',
            render: (text, record) => {
              return <Space size="small">
                {
                  permisosUsuario ?
                    <>
                      {/* <AnexoIExportarExcel datos={datosExcel} empleador={empleador} /> */}
                      <AnexoIExportarExcel datos={cargarEstablecimientoExcel(record)} empleador={empleador} />
                      <EditButton onClick={() => props.onEdit(record)} />
                      <DeleteButton onConfirm={() => props.onRemove(record)} />
                    </> : null
                }
                {/* {!record.IdEstablecimientoDeclarado ? <DeleteButton onConfirm={() => props.onRemove(record)} /> : ''} */}
              </Space>
            }
          } : {},
        ]}
        dataSource={props.EstablecimientosDeclarados}
        rowKey={(record, index) => index}
        pagination={{ hideOnSinglePage: true, pageSize: 10, showSizeChanger: false }}
        expandable={{
          expandedRowRender: record => <Card>
            <Card title="Puestos" type="inner" style={{ marginBottom: 16 }}>
              {record.Puestos?.length > 0
                ? <AnexoIPuestosTable Puestos={record.Puestos} />
                : ''}
            </Card>
            <Card title="Sectores" type="inner" style={{ marginBottom: 16 }}>
              {record.Sectores?.length > 0
                ? <AnexoISectoresTable Sectores={record.Sectores} />
                : ''}
            </Card>
            <Card title="Responsables" type="inner" style={{ marginBottom: 16 }}>
              {record.Responsables?.length > 0
                ? <AnexoIResponsablesTable Responsables={record.Responsables} />
                : ''}
            </Card>
            <Card title="Contratistas" type="inner" style={{ marginBottom: 16 }}>
              {record.Contratistas?.length > 0
                ? <AnexoIContratistasTable Contratistas={record.Contratistas} />
                : ''}
            </Card>

          </Card>
        }}
      />
    </>
  );
}

export default AnexoIEstablecimientosDeclaradosTable;
